<template>
  <b-row class="match-height">
    <b-col lg="12" md="12">
      <div v-if="transportOptions.length === 0" class="mt-4">
        <b-card
          title="Ajouter des options de transport"
          header-tag="header"
          body-class="text-center"
          class="mx-5"
        >
          <b-card-text>
            Créer une ou plusieurs options de transport pour votre groupe et
            ajouter vos inventaires.
          </b-card-text>

          <b-button variant="primary" @click="showCreateTransportOptionModal"
            >Ajouter</b-button
          >
        </b-card>
      </div>
      <div class="mt-2" v-else>
        <h3 class="float-left">
          Options de transport ({{ transportOptions.length }})
        </h3>
        <b-button
          v-if="canEdit"
          variant="success"
          class="float-right"
          @click="showCreateTransportOptionModal"
        >
          + Ajouter option transport
        </b-button>
      </div>
      <div v-for="(option, index) in transportOptions" :key="option.name">
        <TransportationCard
          v-if="hasOneOption"
          :transport-option="option"
          :index="index"
          :canEdit="canEdit"
          :show-contract-number="isOrgAdmin()"
          @edit="showEditTransportOptionModal(index)"
        >
          <template #footer>
            <b-row>
              <b-col cols="6">
                <b-row class="mb-1">
                  <b-col cols="12">
                    <h3 class="float-left">Départ</h3>
                    <b-button
                      v-if="canEdit"
                      variant="success"
                      class="float-right"
                      @click="showAddFlightModal('departures', index)"
                      size="sm"
                    >
                      + Ajouter segment aller</b-button
                    >
                  </b-col>
                </b-row>
                <div
                  class="p-2"
                  v-if="option.departures && option.departures.length == 0"
                >
                  <h4 class="text-muted"><i>Aucun segment de départ</i></h4>
                </div>
                <div
                  v-for="(flight, flightIndex) in option.departures"
                  :key="flight.transport_number"
                >
                  <TransportationSegmentCard
                    :transportation-segment="flight"
                    :can-edit="canEdit"
                    @openEditFlightModal="
                      showEditFlightModal(flight.id, index, 'departures')
                    "
                    @deleteSegment="
                      deleteTransportationSegment(option.id, flight.id)
                    "
                  />
                  <TransportationSegmentModal
                    :ref="`transportation-segment-edit-modal-${flight.id}`"
                    action="update"
                    :current-transport-segment="flight"
                    transport-type="flight"
                    @delete="deleteFlightSegment($event, index, flightIndex)"
                    @edit="editFlightSegment($event, index, flightIndex)"
                  />
                </div>
              </b-col>
              <b-col cols="6">
                <b-row class="mb-1">
                  <b-col cols="12">
                    <h3 class="float-left">Retour</h3>
                    <b-button
                      variant="success"
                      class="float-right"
                      v-if="canEdit"
                      @click="showAddFlightModal('returns', index)"
                      size="sm"
                    >
                      + Ajouter segment retour
                    </b-button>
                  </b-col>
                </b-row>
                <div
                  class="p-2"
                  v-if="option.returns && option.returns.length == 0"
                >
                  <h4 class="text-muted"><i>Aucun segment de retour</i></h4>
                </div>
                <div
                  v-for="(flight, flightIndex) in option.returns"
                  :key="flight.transport_number"
                >
                  <TransportationSegmentCard
                    :transportation-segment="flight"
                    :can-edit="canEdit"
                    @openEditFlightModal="
                      showEditFlightModal(flight.id, index, 'returns')
                    "
                    @deleteSegment="
                      deleteTransportationSegment(option.id, flight.id)
                    "
                  />
                  <TransportationSegmentModal
                    :ref="`transportation-segment-edit-modal-${flight.id}`"
                    action="update"
                    :current-transport-segment="flight"
                    transport-type="flight"
                    @delete="deleteFlightSegment($event, index, flightIndex)"
                    @edit="editFlightSegment($event, index, flightIndex)"
                  />
                </div>
              </b-col>
            </b-row>

            <GroupCreateTransportOptionsModal
              action="update"
              :ref="`edit-transport-option-modal-${index}`"
              :current-transportation="option"
              transport-type="flight"
              @deleteTransportOption="deleteTransportOption($event, index)"
              @editTransportOption="editTransportOption($event, index)"
            />
          </template>
        </TransportationCard>
      </div>

      <div class="text-right">
        <b-button variant="primary" class="ml-1" @click="$emit('next-tab')"
          >Étape suivante</b-button
        >
      </div>
    </b-col>
    <GroupCreateTransportOptionsModal
      ref="transport-option-modal"
      transport-type="flight"
      @createNewTransportOption="createGroupTransportOption"
    />

    <TransportationSegmentModal
      ref="flight-segment-modal"
      action="new"
      transport-type="flight"
      @create="createFlightSegment"
    />
  </b-row>
</template>

<script>
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardText,
  BButton,
  BButtonGroup,
  BCollapse,
  VBToggle,
} from "bootstrap-vue";
import { GroupTransportationService } from "@/views/groups/services/GroupTransportationService.js";
import { useApplicationContext } from "@/shared/composables/use-application-context";
import { onMounted, nextTick, reactive, ref } from "@vue/composition-api";
import { useToastNotification } from "@/shared/composables/use-toast-notifications";
import { useGroupApi } from "@/modules/group/composables/use-group-api";
import { useLocalisation } from "@/shared/composables/use-localisation";
import groupCreationConfig from "@/@core/data/groupCreationConfig";
import store from "@/store";
import {
  AUTH_STORE_NAMESPACE,
  AUTHENTICATED_USER_GETTER,
} from "@/modules/authnz/models/store";
import Roles from "@/modules/authnz/models/role";

import TransportationCard from "./TransportationCard.vue";
import TransportationSegmentCard from "./TransportationSegmentCard.vue";
import GroupCreateTransportOptionsModal from "./GroupCreateTransportOptionsModal.vue";
import GroupEditTransportOptionsModal from "./GroupEditTransportOptionsModal.vue";
import TransportationSegmentModal from "./TransportationSegmentModal.vue";

export const groupTransportationService = new GroupTransportationService();

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BButton,
    BCollapse,
    BButtonGroup,
    TransportationSegmentCard,
    GroupCreateTransportOptionsModal,
    GroupEditTransportOptionsModal,
    TransportationSegmentModal,
    TransportationCard,
  },
  props: {
    groupId: {
      type: String,
      required: false,
      default: "NOT_PROVIDED_BY_PARENT_COMPONENT",
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentFlightOption: {
        direction: null,
        transportOptionIndex: null,
      },
      currentTransportOption: {
        index: null,
      },
      editElement: {},
    };
  },
  directives: {
    "b-toggle": VBToggle,
  },
  setup(props) {
    const { refOf, forceUpdate } = useApplicationContext();
    const { displaySuccessMessage, displayErrorMessage } =
      useToastNotification();
    const { getGroupTransportOptions } = useGroupApi();
    const { t } = useLocalisation();
    let currentRole = ref(null);

    currentRole.value =
      store.getters[
        `${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`
      ].role;

    let hasOneOption = ref(false);
    let transportOptions = reactive([]);

    let currentFlightOption = reactive({
      direction: null,
      transportOptionIndex: null,
      flightSegmentId: null,
    });

    let currentTransportOption = reactive({
      index: null,
    });

    const fetchGroupTransportOptions = async (groupId) => {
      if (
        props.groupId != null &&
        props.groupId != "NOT_PROVIDED_BY_PARENT_COMPONENT"
      ) {
        try {
          const retrievedTransportOptions = await getGroupTransportOptions(
            groupId,
            "flight"
          );

          toggleTransportOptionsList(retrievedTransportOptions);

          Object.assign(transportOptions, retrievedTransportOptions);
          forceUpdate();
        } catch (e) {
          console.error(e);
          displayErrorMessage(
            "Une erreur s'est produite en essayant de retrouver les informations du groupe."
          );
        }
      }
    };
    onMounted(() => {
      fetchGroupTransportOptions(props.groupId);
    });

    const createGroupTransportOption = async (newOption) => {
      let transportOption = Object.assign({}, newOption);
      let currentGroupId = props.groupId;

      return new Promise((resolve, reject) => {
        groupTransportationService
          .createFlightTransportation(currentGroupId, transportOption)
          .then((response) => {
            nextTick(() => {
              fetchGroupTransportOptions(props.groupId);
              forceUpdate();
            });

            toggleTransportOptionsList(transportOptions);
            //this.displayCreationConfirmation();
            refOf("transport-option-modal").$children[0].$children[0].hide();
            displaySuccessMessage("Option ajoutée avec succès");

            resolve(true);
          })
          .catch((error) => {
            console.error(error);
            displayErrorMessage(
              "Une erreur s'est produite lors de la sauvegarde de l'option. Veuillez réessayer."
            );
            reject(true);
          });
      });
    };

    const createFlightSegment = async (newFlightSegment) => {
      let flightSegment = Object.assign({}, newFlightSegment);

      let index = currentFlightOption.transportOptionIndex;
      let direction = currentFlightOption.direction;
      let currentGroupId = props.groupId;
      let transportOptionId = transportOptions[index].id;

      return new Promise((resolve, reject) => {
        groupTransportationService
          .addFlightToTransportOption(
            currentGroupId,
            transportOptionId,
            direction,
            flightSegment
          )
          .then((response) => {
            transportOptions[index][direction].push(flightSegment);

            nextTick(() => {
              fetchGroupTransportOptions(props.groupId);
              forceUpdate();
            });
            resolve(true);
          })
          .catch((error) => {
            console.error(error);
            reject(true);
          });
      });
    };

    const showEditFlightModal = (flight_id, index, direction) => {
      refOf(
        `transportation-segment-edit-modal-${flight_id}`
      )[0].$children[0].$children[0].show();
      currentFlightOption.flightSegmentId = flight_id;
      currentFlightOption.direction = direction;
      currentFlightOption.transportOptionIndex = index;
    };

    const showAddFlightModal = (direction, transportOptionIndex) => {
      currentFlightOption.direction = direction;
      currentFlightOption.transportOptionIndex = transportOptionIndex;
      refOf("flight-segment-modal").$children[0].$children[0].show();
    };

    const showEditTransportOptionModal = (index) => {
      refOf(
        "edit-transport-option-modal-" + index
      )[0].$children[0].$children[0].show();
      currentTransportOption.index = index;
    };

    const toggleTransportOptionsList = (list) => {
      if (list.length > 0) {
        hasOneOption.value = true;
      }
    };

    const editTransportOption = (editedOption, index) => {
      let transportOption = Object.assign({}, editedOption);
      let currentGroupId = props.groupId;

      let originalId = transportOptions[index].id;
      return new Promise((resolve, reject) => {
        groupTransportationService
          .editTransportOption(currentGroupId, originalId, transportOption)
          .then((response) => {
            transportOptions[index] = transportOption;
            displaySuccessMessage("Option modifiée avec succès");
            forceUpdate();
            resolve(true);
          })
          .catch((error) => {
            console.error(error);
            displayErrorMessage(
              "Une erreur s'est produite lors de la sauvegarde de l'option. Veuillez réessayer."
            );
            reject(true);
          });
      });
    };

    const deleteTransportOption = (editedOption, index) => {
      let currentGroupId = props.groupId;
      let originalId = transportOptions[index].id;
      return new Promise((resolve, reject) => {
        groupTransportationService
          .deleteTransportOption(currentGroupId, originalId)
          .then((response) => {
            transportOptions.splice(index, 1);

            displaySuccessMessage("Option modifiée avec succès");
            nextTick(() => {
              fetchGroupTransportOptions(currentGroupId);
              forceUpdate();
            });
            resolve(true);
          })
          .catch((error) => {
            console.error(error);
            displayErrorMessage(
              "Une erreur s'est produite lors de la sauvegarde de l'option. Veuillez réessayer."
            );
            reject(true);
          });
      });
    };

    const editFlightSegment = (editedFlightSegment, index, flightIndex) => {
      let flightSegmentId = currentFlightOption.flightSegmentId;
      let currentGroupId = props.groupId;
      let optionId = transportOptions[index].id;
      let direction = currentFlightOption.direction;

      return new Promise((resolve, reject) => {
        groupTransportationService
          .editFlightSegment(
            currentGroupId,
            optionId,
            flightSegmentId,
            editedFlightSegment
          )
          .then((response) => {
            transportOptions[index][direction][flightIndex] =
              editedFlightSegment;

            displaySuccessMessage("Option modifiée avec succès");
            forceUpdate();
            resolve(true);
          })
          .catch((error) => {
            console.error(error);
            displayErrorMessage(
              "Une erreur s'est produite lors de la sauvegarde de l'option. Veuillez réessayer."
            );
            reject(true);
          });
      });
    };

    const deleteFlightSegment = (editedFlightSegment, index, flightIndex) => {
      let flightSegmentId = currentFlightOption.flightSegmentId;
      let currentGroupId = props.groupId;
      let optionId = transportOptions[index].id;
      let direction = currentFlightOption.direction;

      return new Promise((resolve, reject) => {
        groupTransportationService
          .deleteFlightSegment(currentGroupId, optionId, flightSegmentId)
          .then((response) => {
            transportOptions[index][direction].splice(flightIndex, 1);
            nextTick(() => {
              fetchGroupTransportOptions(props.groupId);
              forceUpdate();
            });
            displaySuccessMessage("Option supprimée avec succès");
            resolve(true);
          })
          .catch((error) => {
            console.error(error);
            displayErrorMessage(
              "Une erreur s'est produite lors de la suppression de l'option. Veuillez réessayer."
            );
            reject(true);
          });
      });
    };

    return {
      fetchGroupTransportOptions,
      transportOptions,
      hasOneOption,
      createGroupTransportOption,
      showEditTransportOptionModal,
      createFlightSegment,
      showEditFlightModal,
      showAddFlightModal,
      t,
      refOf,
      editTransportOption,
      editFlightSegment,
      deleteFlightSegment,
      deleteTransportOption,
      currentRole,
      Roles,
    };
  },
  methods: {
    showCreateTransportOptionModal() {
      this.$refs["transport-option-modal"].$children[0].$children[0].show();
    },
    isOrgAdmin() {
      return Roles.roleIsAllowed(this.currentRole.role, [
        this.Roles.ORGANIZATION_ADMIN.role,
        this.Roles.ORGANIZATION_COORDINATOR.role,
      ]);
    },
    deleteTransportationSegment(transportId, segmentId) {
      this.$swal({
        title: "Suppression du segment",
        text: "Voulez-vous vraiment supprimer ce segment?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Annuler",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.value) {
            groupTransportationService
              .deleteFlightSegment(this.groupId, transportId, segmentId)
              .then((response) => {
                this.$swal({
                  icon: "success",
                  title: "Supprimé!",
                  text: "Le segment a été supprimée.",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
                this.fetchGroupTransportOptions(this.groupId);
              });
          }
        })
        .catch((e) => {
          console.error(e);
          this.$swal({
            icon: "error",
            title: "Erreur de suppression",
            text: `Une erreur s'est produite lors de la suppression du segment. Veuillez réessayer.`,
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        });
    },
  },
};
</script>
<style scoped>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
